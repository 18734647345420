/*!
The Custom Frontend JS (loads in HEADER)

Be very selective of what you load here, only things that NEED to be loaded in the header.
*/

/*
 * ***
 * Begin page-top manipulation
 * ***
 */

/**
 * Gets the current Bootstrap breakpoint based on viewport size and breakpoint pixel sizes set in
 * the theme.
 *
 * @return {string} The detected breakpoint ('xxl', 'xl', 'lg', 'md', 'sm', or 'xs').
 */
function getCurrentBreakpoint() {
	// Get the vieport width at the time of this function running.
	const viewportWidth = document.documentElement.clientWidth;

	/**
	 * Define responsive breakpoints in descending order. The breakpoint ranges will be generated
	 * based on CSS vars that are computed during the build process.
	 */
	const breakpointSizes = ['xxl', 'xl', 'lg', 'md', 'sm', 'xs'];

	// Loop over each breakpoint size. Using var so that size can break out the scope of the loop.
	// eslint-disable-next-line no-var
	for (var size of breakpointSizes) {
		/**
		 * Gets the Bootstrap breakpoint size of the current breakpoint being checked in the loop.
		 * Using parseInt() to convert NNNpx to NNN.
		 */
		const bsBreakpointSize = parseInt(
			getComputedStyle(document.body).getPropertyValue(
				'--mdb-breakpoint-' + size
			)
		);

		// Check if the element is hidden for the current breakpoint.
		if (viewportWidth >= bsBreakpointSize) {
			// Break out of the loop. Now size will be left at the current breakpoint.
			break;
		}
	}

	return size;
}

/**
 * Moves the 'bsu-page-top-secondary' element around the DOM based on the responsive breakpoints.
 * Ensures proper placement and styling for page-top elements.
 */
function bsuPageTopPlacement() {
	// Get references to the required page top elements
	const pt1 = document.getElementById('bsu-page-top-primary');
	const pt2 = document.getElementById('bsu-page-top-secondary');
	const pt2p = document.getElementById('bsu-page-top-secondary-placeholder');

	// Ensure the necessary page top elements exist
	if (pt1 && pt2 && pt2p) {
		// Add classes for styling when JavaScript is enabled
		pt1.classList.add('col-lg-7');
		pt2.classList.add('col-lg-5');
		pt2p.classList.add('d-lg-none');

		// Update the DOM based on the responsive breakpoint to place 'page-top-secondary'
		switch (getCurrentBreakpoint()) {
			case 'xs':
			case 'sm':
			case 'md':
				// Place 'page-top-secondary' inside the placeholder for smaller breakpoints
				pt2p.prepend(pt2);
				break;
			case 'lg':
			case 'xl':
			case 'xxl':
				// Place 'page-top-secondary' inside 'page-top' after 'page-top-primary' for larger breakpoints
				pt1.after(pt2);
				break;
		}

		// Make CTA buttons visible
		pt2.classList.remove('d-none');
		pt2.classList.add('d-sm-flex');

		// Apply animation only on page load
		if (!window.page_top_placement_already_ran) {
			const pt2Classes = pt2.className.split(' ');

			if (
				!pt2Classes.includes('animated') ||
				!pt2Classes.includes('fadeIn')
			) {
				pt2.classList.add('animated', 'fadeIn');
			}
		} else {
			pt2.classList.remove('animated', 'fadeIn');
		}

		// Indicate that the function has already run once
		window.page_top_placement_already_ran = true;
	}
}
// Run asap before CSS and images are done.
document.addEventListener('DOMContentLoaded', bsuPageTopPlacement);

// Run on resize.
window.addEventListener('resize', bsuPageTopPlacement);

/*
 * ***
 * End page-top manipulation
 * ***
 */
